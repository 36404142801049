$font-primary: "Segoe UI", sans-serif;
$font-secondary: "Segoe UI Light", sans-serif;
$font-tertiary: "Segoe UI Semibold", sans-serif;
$ava-orange: #ff5800;
$ava-orange-dark: #dc4600;
$ava-orange-transparent-10: rgba(255, 88, 0, 0.1);
$ava-orange-transparent-20: rgba(255, 88, 0, 0.2);
$ava-orange-transparent-40: rgba(255, 88, 0, 0.4);
$ava-orange-transparent-60: rgba(255, 88, 0, 0.6);
$ava-orange-tone-1: #ffeee5;
$ava-orange-tone-2: #ffdecc;
$ava-orange-tone-3: #ffcdb3;
$ava-orange-tone-4: #ffbc99;
$ava-orange-tone-5: #ffab7f;
$ava-red: #c80000;
$ava-red-transparent: rgba(200, 0, 0, 0.2);
$ava-red-dark: #970032;
$ava-purple: #980078;
$ava-purple-transparent-10: rgba(152, 0, 120, 0.1);
$ava-purple-transparent-20: rgba(152, 0, 120, 0.2);
$ava-purple-transparent-40: rgba(152, 0, 120, 0.4);
$ava-purple-transparent-60: rgba(152, 0, 120, 0.6);
$ava-purple-tone-1: #f4e5f1;
$ava-purple-tone-2: #eacce4;
$ava-purple-tone-3: #d699c9;
$ava-purple-tone-4: #c166ae;
$ava-purple-transparent: rgba(152, 0, 120, 0.2);
$ava-yellow: #ffb414;
$ava-aurora: $ava-orange, $ava-purple;
$ava-thermal: $ava-orange, $ava-red-dark;
$ava-luminous: $ava-yellow, $ava-orange;
$gray-80: #333;
$gray-70: #4c4c4c;
$gray-60: #666;
$gray-50: #7f7f7f;
$gray-40: #999;
$gray-30: #b3b3b3;
$gray-20: #ccc;
$gray-10: #e5e5e5;
