@use "variables";

@-webkit-keyframes anim-background {
  0% {
    background-position: 0% 50
  }

  50% {
    background-position: 100% 50%
  }

  100% {
    background-position: 0% 50
  }
}

@-moz-keyframes anim-background {
  0% {
    background-position: 0% 50
  }

  50% {
    background-position: 100% 50%
  }

  100% {
    background-position: 0% 50
  }
}

@keyframes anim-background {
  0% {
    background-position: 0% 50
  }

  50% {
    background-position: 100% 50%
  }

  100% {
    background-position: 0% 50
  }
}

body {
  font-family: variables.$font-primary;
  font-size: 12pt;
  color: variables.$gray-70;
  line-height: 1.5;
  margin: auto;
  background-size: 200% 200%;
  background-image: linear-gradient(270deg, #ff5800, #980078);
  -webkit-animation: anim-background 30s ease infinite;
  -moz-animation: anim-background 30s ease infinite;
  animation: anim-background 30s ease infinite;
}

h1,
h2 {
  font-family: variables.$font-secondary;
  font-size: 2.34em;
  font-weight: normal;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
}

h2 {
  margin-block-start: 1.23em;
  margin-block-end: 0.41em;
}

a:link {
  color: variables.$ava-orange;
  font-family: variables.$font-tertiary;
}

a:visited {
  color: variables.$ava-orange-dark;
}

a:hover {
  color: variables.$ava-red;
}

.grid-page-layout {
  display: grid;
  grid-template-columns: 30vw 1fr 30vw;
  grid-template-rows: 10vh 90vh;

  .cell-1,
  .cell-2,
  .cell-3,
  .cell-4,
  .cell-5,
  .cell-6,
  .cell-7,
  .cell-8,
  .cell-9 {
    display: grid;
  }

  .cell-2 {
    margin: auto;
    text-align: middle;

    img {
      height: 10vh;
    }
  }
}

.content {
  background-color: white;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  display: block;
  padding: 50px 100px;

  .doc-site-links {
    font-family: variables.$font-tertiary;
    padding: 0 20px;
    margin: 1em 0;

    p {
      margin: 1em 0;

      a {
        line-height: 2.5;
      }
    }

    a:link {
      color: white;
      padding: 5px 20px;
      border-radius: 20px;
      background-color: variables.$ava-orange-dark;
      text-decoration: none;
      margin-right: 10px;
    }

    a:visited {
      color: white;
    }

    a:hover {
      background-color: variables.$ava-red;
    }
  }
}

.text-center {
  text-align: center;
}

.go-orange {
  color: variables.$ava-orange;
}

@media (max-width: 1800px) {
  .grid-page-layout {
    grid-template-columns: 25vw 1fr 25vw;
  }
}

@media (max-width: 1600px) {
  .grid-page-layout {
    grid-template-columns: 20vw 1fr 20vw;
  }
}

@media (max-width: 1400px) {
  .grid-page-layout {
    grid-template-columns: 15vw 1fr 15vw;
  }

  .content {
    .doc-site-links {
      a:link {
        margin-right: 2px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .grid-page-layout {
    grid-template-columns: 10vw 1fr 10vw;
  }

  .content {
    .doc-site-links {
      a:link {
        margin-right: 2px;
      }
    }
  }
}